import { useState } from "react";
import { ymGoal } from "ym";
import { ReactComponent as SvgVk } from "components/Socials/vk.svg";
import { ReactComponent as SvgTwitter } from "components/Socials/twitter.svg";
import { ReactComponent as SvgTelegram } from "components/Socials/telegram.svg";
import { ReactComponent as SvgWhatsApp } from "components/Socials/whatsapp.svg";
import { ReactComponent as SvgFacebook } from "components/Socials/facebook.svg";
import { ReactComponent as SvgShare } from "./share.svg";
import styles from "./Share.module.css";

export function Share() {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles.root} onClick={() => ymGoal("click_6")}>
      <div className={styles.title}>Поделитесь дзеном:</div>
      <div className={styles.image} onClick={() => setOpen(!open)}>
        <SvgShare/>
      </div>
      {
        open &&
        <div className={styles.dropdown}>
          <ShareButtons/>
        </div>
      }
    </div>
  );
}

function ShareButtons() {
  const url = document.querySelector("meta[property='og:url']")?.content ?? window.location.href;
  const title = document.querySelector("meta[property='og:title']")?.content ?? document.title;

  function createUrl(template) {
    template = template.replace("{url}", encodeURIComponent(url));
    template = template.replace("{title}", encodeURIComponent(title));

    return template;
  }

  return (
    <>
      {
        false &&
        <a href={createUrl("https://www.facebook.com/dialog/share?app_id=140586622674265&display=popup&href={url}")} target="_blank" rel="noreferrer noopener">
          <SvgFacebook/>
        </a>
      }
      <a href={createUrl("http://vk.com/share.php?url={url}&title={title}")} target="_blank" rel="noreferrer noopener">
        <SvgVk/>
      </a>
      <a href={createUrl("https://twitter.com/intent/tweet?url={url}&text={title}")} target="_blank" rel="noreferrer noopener">
        <SvgTwitter/>
      </a>
      <a href={createUrl("https://t.me/share/url?url={url}&text={title}")} target="_blank" rel="noreferrer noopener">
        <SvgTelegram/>
      </a>
      <a href={createUrl("https://wa.me/?text={title}%20-%20{url}")} data-action="share/whatsapp/share" target="_blank" rel="noreferrer noopener">
        <SvgWhatsApp/>
      </a>
    </>
  );
}
