import { useEffect, useState } from "react";
import cs from "classnames";
import { Block } from "components/Block";
import { BannerAdfox } from "components/BannerAdfox";
import styles from "./BlockBanner.module.css";

export function BlockBanner(props) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show) {
      props.onUpdate?.();
    }
  }, [show, props]);

  return (
    <Block className={cs(styles.root, show && styles.show)}>
      <BannerAdfox ownerId={257448} params={{ p1: "csejs", p2: "gyuh" }} onRender={() => setShow(true)}/>
    </Block>
  );
}
