import React from "react";
import getYouTubeID from "get-youtube-id";
import { EmbedResponsive } from "components/EmbedResponsive";

function getIframeUrl(url) {
  const id = getYouTubeID(url, { fuzzy: false });

  if (!id) {
    console.warn("Unable to get YouTube ID from URL %o", url);

    return url;
  }

  const originalUrl = new URL(url);

  const iframeUrl = new URL(`https://www.youtube.com/embed/${encodeURIComponent(id)}`);

  if (originalUrl.searchParams.has("t")) {
    iframeUrl.searchParams.set("start", originalUrl.searchParams.get("t"));
  }

  if (originalUrl.searchParams.has("start")) {
    iframeUrl.searchParams.set("start", originalUrl.searchParams.get("start"));
  }

  return iframeUrl.toString();
}

export function Youtube(props) {
  return (
    <EmbedResponsive>
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe src={getIframeUrl(props.url)} allow="fullscreen"/>
    </EmbedResponsive>
  );
}
