import { useEffect, useRef } from "react";
import cs from "classnames";
import VideoWebMUrl from "./bg.webm";
import VideoMp4Url from "./bg.mp4";
import styles from "./BackgroundVideo.module.css";

export function BackgroundVideo() {
  const mounted = useRef();

  const ref = useRef();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }

    ref.current.load();
  }, []);

  return (
    <>
      <div className={cs(styles.root, styles.image)}></div>
      <video ref={ref} playsInline autoPlay muted loop className={cs(styles.root, styles.video)}>
        <source src={VideoWebMUrl} type="video/webm"/>
        <source src={VideoMp4Url} type="video/mp4"/>
      </video>
    </>
  );
}
