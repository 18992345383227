import { useEffect, useState } from "react";
import axios from "axios";
import env from "env";
import styles from "./MatchWidget.module.css";

export function MatchWidget({ matchUrn }) {
  const [match, setMatch] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    try {
      const { data } = await axios.get(`${env("API_URL")}/api-aggregator/v2/events/${encodeURIComponent(matchUrn)}/reviewShort`);
      setMatch(data.header);
    } catch (e) {
      console.error(e);
    }
  }, [matchUrn]);

  if (!match) {
    return null;
  }

  return (
    <div className={styles.root}>
      Матч!
    </div>
  );
}
