import { useEffect, useState } from "react";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import styles from "./CurrentDateTime.module.css";

const INTERVAL = 60 * 1000;
const FORMAT_DATE = "eeee, d MMMM, ";
const FORMAT_TIME = "H:mm";

export function CurrentDateTime() {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const id = setInterval(() => setDate(new Date()), INTERVAL);
    return () => clearInterval(id);
  }, [setDate]);

  return (
    <div className={styles.root}>
      <span className={styles.date}>{format(date, FORMAT_DATE, { locale: ru })}</span>
      <span className={styles.time}>{format(date, FORMAT_TIME, { locale: ru })}</span>
    </div>
  );
}
