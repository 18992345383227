import env from "./env";

function createImageUrl(image, width, height, extension) {
  return `${env("MEDIA_BASE_URL")}/m/${image}/${width}_${height}_max.${extension}`;
}

function getImage(id) {
  if (id.startsWith("/m/")) {
    id = id.substr(3);
  }

  return id.split("/").slice(0, -1).join("/");
}

export function createImageUrlById(id, width, height) {
  const image = getImage(id);
  const extension = id.split(".")[1];

  return createImageUrl(image, width, height, extension);
}
