const values = {
  API_URL: process.env.REACT_APP_API_URL,
  MEDIA_BASE_URL: process.env.REACT_APP_MEDIA_BASE_URL,
  SITE_BASE_URL: process.env.REACT_APP_SITE_BASE_URL,
  LIVE_API_URL: process.env.REACT_APP_LIVE_API_URL,
};

export default function env(key) {
  return window.env?.[key] ?? values[key];
}
