import { useConfig } from "components/App/ConfigContext";
import { Socials } from "components/Socials";
import styles from "./Footer.module.css";

export function Footer() {
  const config = useConfig();

  return (
    <footer className={styles.root}>
      <div className={styles.title}>{config["footer-socials-title"]}</div>
      <Socials/>
    </footer>
  );
}
