import { useEffect, useRef, useState } from "react";
import { useConfig } from "components/App/ConfigContext";
import { Block } from "components/Block";
import styles from "./BlockVideo.module.css";

export function BlockVideo(props) {
  const { visible, autoplay } = props;

  const playerRef = useRef();

  const [exists, setExists] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const t = setInterval(() => {
      if (window.YT?.Player) {
        setExists(true);
        clearInterval(t);
      }
    }, 100);

    return () => clearInterval(t);
  }, []);

  const config = useConfig();

  useEffect(() => {
    if (exists) {
      playerRef.current = new window.YT.Player("video", {
        videoId: config["video-id"],
        width: 640,
        height: 360,
        events: {
          onReady: event => {
            if (autoplay) {
              const player = event.target;

              player.playVideo();

              setTimeout(() => {
                if (player.getPlayerState() === window.YT.PlayerState.UNSTARTED) {
                  if (!player.isMuted()) {
                    player.mute();
                    player.playVideo();
                  }
                }
              }, 2000);
            }
            setReady(true);
          },
        },
      });
    }
  }, [exists, autoplay, config]);

  useEffect(() => {
    if (ready) {
      if (!autoplay) {
        if (visible) {
          playerRef.current.playVideo();
        } else {
          playerRef.current.pauseVideo();
        }
      }
    }
  }, [ready, visible, autoplay]);

  return (
    <Block title={config["video-title"]} className={styles.root} visible={visible}>
      <div className={styles.video}>
        <div id="video"/>
      </div>
    </Block>
  );
}
