import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { App } from "components/App";
import "./index.css";

const requestEnv = axios.get(process.env.PUBLIC_URL + "/config.json", {
  timeout: 5000,
});

const requestConfig = axios.get("https://api.side.sport24.ru/", {
  params: {
    site: "OlympicZen2022",
    method: "Config",
  },
  timeout: 5000,
});

Promise.all([requestEnv, requestConfig]).then(([responseEnv, responseConfig]) => {
  if (process.env.NODE_ENV === "production") {
    window.env = responseEnv.data;
  }

  ReactDOM.render(
    <React.StrictMode>
      <App config={responseConfig.data}/>
    </React.StrictMode>,
    document.getElementById("root"),
  );
});
