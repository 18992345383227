import styles from "./Menu.module.css";
import cs from "classnames";

export function Menu(props) {
  const { slide, toSlide } = props;

  return (
    <div className={styles.root}>
      <div className={styles.list}>
        <div className={cs(styles.item, slide === 0 && styles.selected)} onClick={() => toSlide(0)}>Новости</div>
        <div className={cs(styles.item, slide === 1 && styles.selected)} onClick={() => toSlide(1)}>LIVE/Прямой эфир</div>
        <div className={cs(styles.item, slide === 2 && styles.selected)} onClick={() => toSlide(2)}>Медали</div>
      </div>
    </div>
  );
}
