import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import cs from "classnames";
import { useIsMobile } from "components/App/IsMobileContext";
import styles from "./Block.module.css";

const root = document.querySelector("#mobile-block-header-root");

export function Block(props) {
  const { title, line = false, right, loading, scroll, className, onClick, visible, children } = props;

  const [ fixed, setFixed ] = useState(false);

  const isMobile = useIsMobile();

  const headerRef = useRef();

  const onScroll = useCallback(() => {
    if (visible && headerRef.current) {
      const bounds = headerRef.current.parentElement.getBoundingClientRect();

      const newFixed = visible && bounds.top < 160 && bounds.bottom >= (160 + headerRef.current.clientHeight);

      setFixed(newFixed);
    }
  }, [visible]);

  useEffect(() => {
    if (isMobile && visible) {
      window.addEventListener("scroll", onScroll, {
        passive: true,
      });

      return () => {
        window.removeEventListener("scroll", onScroll);
      };
    }
  }, [onScroll, isMobile, visible]);

  useEffect(onScroll, [onScroll]);

  return (
    <div className={cs(styles.root, loading && styles.loading, scroll && styles.scroll, className)} onClick={onClick}>
      {title && fixed && createPortal((
        <div className={cs(styles.root, loading && styles.loading, scroll && styles.scroll, className, styles.fixed)} onClick={onClick}>
          <div className={cs(styles.header, line && styles.line)}>
            <div className={styles.title}>{title}</div>
            {right && <div className={styles.right}>{right}</div>}
          </div>
          <div className={styles.body}/>
        </div>
      ), root)}
      {
        title &&
        <div ref={headerRef} className={cs(styles.header, line && styles.line)}>
          <div className={styles.title}>{title}</div>
          {right && <div className={styles.right}>{right}</div>}
        </div>
      }
      <div className={styles.body}>
        {children}
      </div>
    </div>
  );
}
