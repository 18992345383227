const medalStyle = {
  width: 12,
  height: 12,
  flexGrow: 0,
  flexShrink: 0,
  borderRadius: "50%",
  boxShadow: "1px 0 2px rgba(0, 0, 0, 0.3)",
};

export function Medals(props) {
  const { gold, silver, bronze, style = {} } = props;

  if (gold) {
    return (
      <div style={{ ...medalStyle, backgroundColor: "#EABD00", ...style }}/>
    );
  }

  if (silver) {
    return (
      <div style={{ ...medalStyle, backgroundColor: "#A1A0A0", ...style }}/>
    );
  }

  if (bronze) {
    return (
      <div style={{ ...medalStyle, backgroundColor: "#DD681A", ...style }}/>
    );
  }

  return (
    <div style={{ display: "flex", position: "relative", zIndex: 0, ...style }}>
      <Medals gold style={{ zIndex: 2, marginRight: -7 }}/>
      <Medals silver style={{ zIndex: 1, marginRight: -7 }}/>
      <Medals bronze/>
    </div>
  );
}
