import { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import { ymGoal } from "ym";
import { useConfig } from "components/App/ConfigContext";
import { Block } from "components/Block";
import { Flag } from "components/Flag";
import { Medals } from "components/Medals";
import { ReactComponent as SvgNoPhoto } from "./nophoto.svg";
import styles from "./BlockMedals.module.css";

const UPDATE_INTERVAL = 60 * 1000;

function useApiData() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [items, setItems] = useState(null);

  async function reload() {
    setLoading(true);
    setError(false);

    try {
      const { data } = await axios.get("https://api.side.sport24.ru", {
        params: {
          site: "OlympicZen2022",
          method: "Medals",
        },
      });

      setItems(data);
    } catch (e) {
      setError(true);
    }

    setLoading(false);
  }

  useEffect(() => {
    reload();

    if (UPDATE_INTERVAL) {
      const interval = setInterval(reload, UPDATE_INTERVAL);

      return () => {
        clearInterval(interval);
      }
    }
  }, []); // eslint-disable-line

  return { items, loading, error };
}

export function BlockMedals(props) {
  const config = useConfig();

  const { items, loading } = useApiData();

  useEffect(() => {
    if (items) {
      props.onUpdate?.();
    }
  }, [props, items]);

  return (
    <Block title={<span>{config["medals-title"]} <Flag code={config["medals-flag-alpha2"]} style={{ fontSize: 12, marginLeft: 2 }}/></span>} line className={styles.root} loading={loading} scroll visible={props.visible} onClick={() => ymGoal("click_4")}>
      {
        items?.map(data =>
          <Item key={data.id} data={data}/>,
        )
      }
    </Block>
  );
}

function Item({ data }) {
  const { publish_time: publishTime, title, photo, url, participant_type: participantType, medal_type: medalType, competition, result } = data;

  const date = new Date(publishTime * 1000);

  const isTeam = participantType === "team";

  const content = (
    <>
      {competition && <div className={styles.competition}>{competition}</div>}
      <div className={styles.photo}>
        {!photo && isTeam && <Flag code="RU"/>}
        {!photo && !isTeam && <SvgNoPhoto/>}
        {photo && <img src={photo} alt={title}/>}
      </div>
      <div className={styles.result}>{result}</div>
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.date}>{format(date, "d MMMM", { locale: ru })}</div>
      <div className={styles.medal}>
        {medalType && <Medals gold={medalType === "gold"} silver={medalType === "silver"} bronze={medalType === "bronze"}/>}
      </div>
    </>
  );

  if (url) {
    return (
      <a href={url} target="_blank" rel="noopener" className={styles.item}>{/* eslint-disable-line react/jsx-no-target-blank */}
        {content}
      </a>
    );
  }

  return (
    <div className={styles.item}>{content}</div>
  );
}
