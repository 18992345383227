import TweetEmbed from "react-tweet-embed";

function getTweetId(url) {
  const urlParser = new URL(url);

  return urlParser.pathname.substring(urlParser.pathname.lastIndexOf("/") + 1);
}

export function TwitterEmbed(props) {
  const { url, card, conversation } = props;

  let id;

  try {
    id = getTweetId(url);
  } catch (e) {
    console.error(e);
    console.warn("Unable to render Tweet. Invalid URL %o", url);

    return null;
  }

  const options = {
    align: "center",
    cards: card ? null : "hidden",
    conversation: conversation ? null : "none",
  };

  return (
    <TweetEmbed id={id} options={options}/>
  );
}
