import { useEffect, useState } from "react";
import axios from "axios";
import styles from "./CurrentWeather.module.css";

const API_KEY = "d99d48d1888e1092d195bd53471062d5";

export function CurrentWeather(props) {
  const { city, title } = props;

  const [data, setData] = useState(null);

  useEffect(() => {
    async function load() {
      const response = await axios.get(`https://api.openweathermap.org/data/2.5/weather?lang=ru&units=metric&q=${encodeURIComponent(city)}&appid=${encodeURIComponent(API_KEY)}`);
      setData(response.data);
    }

    load();
  }, [city]);

  if (!data) {
    return null;
  }

  const temp = Math.round(data.main.temp);

  const { icon, description } = data.weather?.[0] ?? {};

  return (
    <div className={styles.root}>
      <span className={styles.title}>{title}</span>
      {icon && <img src={`https://openweathermap.org/img/wn/${icon}@2x.png`} alt={description} title={description}/>}
      {temp > 0 ? `+${temp}` : temp}
    </div>
  );
}
