import { useCallback, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { useConfig } from "components/App/ConfigContext";
import { BlockRating } from "components/BlockRating";
import { BlockMedals } from "components/BlockMedals";
import { BlockNews } from "components/BlockNews";
import { BlockBanner } from "components/BlockBanner";
import { BlockLive } from "components/BlockLive";
import { BlockVideo } from "components/BlockVideo";
import { Menu } from "components/Menu";
import { Footer } from "components/Footer";
import { BannerAdfox } from "components/BannerAdfox";
import styles from "./MainMobile.module.css";

export function MainMobile() {
  const [swiper, setSwiper] = useState();
  const [slide, setSlide] = useState(0);

  const config = useConfig();

  const toSlide = useCallback(n => {
    swiper?.slideTo(n);
  }, [swiper]);

  const update = useCallback(() => {
    swiper?.update();
  }, [swiper]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slide]);

  return (
    <>
      <Menu slide={slide} toSlide={toSlide}/>
      <div className={styles.root}>
        <Swiper
          autoHeight
          centeredSlides
          slidesPerView="auto"
          resistanceRatio={0.5}
          onSwiper={swiper => setSwiper(swiper)}
          onSlideChange={swiper => setSlide(swiper.activeIndex)}
        >
          {
            (config.news || config["banners-left"]) &&
            <SwiperSlide>
              {config.news && <BlockNews onUpdate={update} visible={slide === 0}/>}
              {config["banners-left"] && <BlockBanner onUpdate={update} visible={slide === 0}/>}
            </SwiperSlide>
          }
          {
            (config.video || config.live) &&
            <SwiperSlide>
              {config.video && <BlockVideo visible={slide === 1}/>}
              {config.live && <BlockLive onUpdate={update} visible={slide === 1}/>}
            </SwiperSlide>
          }
          {
            (config.rating || config.medals) &&
            <SwiperSlide>
              {config.rating && <BlockRating onUpdate={update} visible={slide === 2}/>}
              {config.medals && <BlockMedals onUpdate={update} visible={slide === 2}/>}
            </SwiperSlide>
          }
        </Swiper>
        <Footer/>
        {config["banners-bottom"] && <BannerAdfox ownerId={257448} params={{ p1: "csejy", p2: "fpjq" }}/>}
      </div>
    </>
  );
}
