import { BackgroundVideo } from "components/BackgroundVideo";
import { Header } from "components/Header";
import { MainDesktop } from "components/MainDesktop";
import { MainMobile } from "components/MainMobile";
import { BannerAdfox } from "components/BannerAdfox";
import { IsDesktop, IsMobile, IsMobileContextProvider } from "./IsMobileContext";
import { ConfigContextProvider } from "./ConfigContext";

export function App({ config }) {
  return (
    <ConfigContextProvider config={config}>
      <IsMobileContextProvider>
        {config["banners-top"] && <BannerAdfox ownerId={257448} params={{ p1: "csejx", p2: "ginl" }} style={{ position: "relative", zIndex: 1 }}/>}
        <IsDesktop>
          <BackgroundVideo/>
        </IsDesktop>
        <Header/>
        <IsDesktop>
          <MainDesktop/>
        </IsDesktop>
        <IsMobile>
          <MainMobile/>
        </IsMobile>
      </IsMobileContextProvider>
    </ConfigContextProvider>
  );
}
