import cs from "classnames";
import styles from "./EmbedResponsive.module.css";

export function EmbedResponsive(props) {
  const { width, height, background, transparent, className, children } = props;

  const style = {};

  if (width && height) {
    style.paddingBottom = `${height / width * 100}%`;
  } else {
    style.paddingBottom = `${9 / 16 * 100}%`;
  }

  if (background) {
    style.backgroundImage = `url(${background})`;
  }

  if (transparent) {
    style.backgroundColor = "transparent";
  }

  return (
    <div className={cs(styles.root, className)} style={style}>
      {children}
    </div>
  );
}
