import { useConfig } from "components/App/ConfigContext";
import { BlockRating } from "components/BlockRating";
import { BlockMedals } from "components/BlockMedals";
import { BlockNews } from "components/BlockNews";
import { BlockLive } from "components/BlockLive";
import { BlockVideo } from "components/BlockVideo";
import { BlockBanner } from "components/BlockBanner";
import { BannerAdfox } from "components/BannerAdfox";
import { Footer } from "components/Footer";
import styles from "./MainDesktop.module.css";

export function MainDesktop() {
  const config = useConfig();

  return (
    <div className="content">
      <div className={styles.root}>
        <div>
          {config.news && <BlockNews className={styles.news}/>}
          {config["banners-left"] && <BlockBanner/>}
        </div>
        <div>
          {config.live && <BlockLive className={styles.live}/>}
        </div>
        <div>
          {config.video && <BlockVideo autoplay={config["video-autoplay"]}/>}
          {config.rating && <BlockRating/>}
          {config.medals && <BlockMedals/>}
        </div>
        {
          config["banners-bottom"] &&
          <div className={styles.banner}>
            <BannerAdfox ownerId={257448} params={{ p1: "csejy", p2: "fpjq" }}/>
          </div>
        }
      </div>
      <Footer/>
    </div>
  );
}
