import { useEffect, useState } from "react";
import { useTransition, animated } from "react-spring";
import cs from "classnames";
import axios from "axios";
import env from "env";
import { useConfig } from "components/App/ConfigContext";
import { Block } from "components/Block";
import { Flag } from "components/Flag";
import { Medals } from "components/Medals";
import styles from "./BlockRating.module.css";

function useApiData() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [items, setItems] = useState(null);

  const config = useConfig();

  async function reload() {
    setLoading(true);
    setError(false);

    try {
      const { data: { items } } = await axios.get(`${env("API_URL")}/hub/v1/statistics/widget/ranking/olympics`, {
        params: {
          rankingPeriodUrn: config["rating-api-period-urn"],
        },
      });
      setItems(items);
    } catch (e) {
      setError(true);
    }

    setLoading(false);
  }

  useEffect(() => reload(), []); // eslint-disable-line

  return { items, loading, error };
}

export function BlockRating(props) {
  const [full, setFull] = useState(false);
  const [rows, setRows] = useState([]);
  const { items, loading } = useApiData();

  const config = useConfig();

  useEffect(() => {
    if (items) {
      const rows = [];

      if (full) {
        rows.push(...items);
        if (rows.length > config["rating-short-limit"]) {
          rows.push(null);
        }
      } else {
        rows.push(...items.slice(0, config["rating-short-limit"]));
        rows.push(null);
        if (!rows.find(row => row?.participant?.region?.iso === config["rating-highlight-alpha2"])) {
          const highlightItem = items.find(item => item.participant.region?.iso === config["rating-highlight-alpha2"]);
          if (highlightItem) {
            rows.push(highlightItem);
          }
        }
      }

      setRows(rows);
    }
  }, [items, full, config]);

  useEffect(() => {
    if (rows) {
      props.onUpdate?.();
    }
  }, [props, rows])

  const transition = useTransition(rows, {
    from: { opacity: 0, x: 30 },
    enter: { opacity: 1, x: 0 },
    trail: 30,
  });

  const fadeInListItems = transition((style, item) => {
    return item ? <Item key={item.participant.id} data={item} style={style}/> : <ItemFull key={full ? "full" : "not-full"} title={full ? "Показать меньше" : "..."} onClick={() => setFull(!full)} important={!full} style={style}/>;
  });

  return (
    <Block title={config["rating-title"]} line className={styles.root} right={<Right/>} loading={loading} visible={props.visible}>
      {fadeInListItems}
    </Block>
  );
}

function Right() {
  return (
    <div className={styles.right}>
      <div className={styles.cell}>
        <Medals gold/>
      </div>
      <div className={styles.cell}>
        <Medals silver/>
      </div>
      <div className={styles.cell}>
        <Medals bronze/>
      </div>
      <div className={styles.cell}>
        <Medals style={{ transform: "translateX(3px)" }}/>
      </div>
    </div>
  );
}

function Item(props) {
  const { participant: { title, region }, rankingTable: { rank, gold, silver, bronze, total } } = props.data;

  return (
    <animated.div className={styles.item} style={props.style}>
      <div className={styles.position}>{rank}</div>
      <div className={styles.flag}><Flag code={region?.iso}/></div>
      <div className={styles.title}><span>{title}</span></div>
      <div className={cs(styles.count, styles.gold)}>{gold}</div>
      <div className={cs(styles.count, styles.silver)}>{silver}</div>
      <div className={cs(styles.count, styles.bronze)}>{bronze}</div>
      <div className={cs(styles.count, styles.total)}>{total}</div>
    </animated.div>
  );
}

function ItemFull(props) {
  const { title, onClick, important, style } = props;

  return (
    <animated.div style={style} className={cs(styles.item, styles.itemFull, important && styles.important)} onClick={onClick}>
      <div className={styles.position}/>
      <div className={styles.flag}/>
      <div className={styles.title}>{title}</div>
    </animated.div>
  );
}
