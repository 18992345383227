import cs from "classnames";
import { createImageUrlById } from "images";
import { Content } from "components/Content";
import { EmbedResponsive } from "components/EmbedResponsive";
import { Youtube } from "components/Youtube";
import { TwitterEmbed } from "components/TwitterEmbed";
import { InstagramEmbed } from "components/InstagramEmbed";
import { MaterialsList } from "components/MaterialsList";
import { MatchWidget } from "components/MatchWidget";
import styles from "./Content.module.css";

export const elements = {
  br: (params, children) => (
    <br/>
  ),
  h2: (params, children) => (
    <h2><Content content={children}/></h2>
  ),
  h3: (params, children) => (
    <h3><Content content={children}/></h3>
  ),
  h4: (params, children) => (
    <h4><Content content={children}/></h4>
  ),
  a: (params, children) => (
    <a href={params.href} target="_blank" className={styles.link}>{/* eslint-disable-line react/jsx-no-target-blank */}
      <Content content={children}/>
    </a>
  ),
  span: (params, children) => (
    <span {...params}>
      <Content content={children}/>
    </span>
  ),
  ol: (params, children) => (
    <ol {...params} className={styles.list}>
      <Content content={children}/>
    </ol>
  ),
  ul: (params, children) => (
    <ul {...params} className={styles.list}>
      <Content content={children}/>
    </ul>
  ),
  li: (params, children) => (
    <li {...params}>
      <Content content={children}/>
    </li>
  ),
  strong: (params, children) => (
    <strong {...params}>
      <Content content={children}/>
    </strong>
  ),
  em: (params, children) => (
    <em {...params}>
      <Content content={children}/>
    </em>
  ),
  b: (params, children) => (
    <b {...params}>
      <Content content={children}/>
    </b>
  ),
  i: (params, children) => (
    <i {...params}>
      <Content content={children}/>
    </i>
  ),
  img: (params, children) => {
    const { src, title } = params;

    if (!src) {
      console.warn("Image without src found", params);

      return null;
    }

    const imageUrl = createImageUrlById(src, 600, 600);

    return (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img src={imageUrl} title={title} className={styles.image}/>
    );
  },
  p: (params, children) => {
    if (children.length === 1 && children[0].length > 1 && children[0][0] === "img") {
      return (
        <Content content={children}/>
      );
    }

    return (
      <p>
        <Content content={children}/>
      </p>
    );
  },
  youtube: (params, children) => (
    <div className={styles.block}>
      <Youtube url={params.url}/>
    </div>
  ),
  rutube: (params, children) => (
    <div className={styles.block}>
      <EmbedResponsive>
        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
        <iframe src={params.url} allow="fullscreen"/>
      </EmbedResponsive>
    </div>
  ),
  iframe: (params, children) => {
    const { transparent, aspectRatio } = params;
    let { url } = params;

    if (!url) {
      return null;
    }

    let urlObject = null;

    try {
      urlObject = new URL(url.startsWith("//") ? `https://${url}` : url);
    } catch (e) {
      console.error(e);
    }

    if (urlObject) {
      if (["www.riddle.com", "riddle.com"].includes(urlObject.hostname)) {
        return null;
      }

      if (["www.ok.ru", "ok.ru"].includes(urlObject.hostname)) {
        return null;
      }

      if (urlObject.hostname === "video.khl.ru" && urlObject.pathname.startsWith("/iframe/")) {
        urlObject.searchParams.set("width", "100%");
        urlObject.searchParams.set("height", "100%");
        url = urlObject.toString();
      }
    }

    let width = null;
    let height = null;

    if (aspectRatio) {
      const match = aspectRatio.match(/^\s*(\d+)\/(\d+)\s*$/);
      if (match && match[1] > 0 && match[2] > 0) {
        width = match[1];
        height = match[2];
      }
    }

    return (
      <div className={styles.block}>
        <EmbedResponsive transparent={transparent} width={width} height={height}>
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <iframe src={url} allow="fullscreen"/>
        </EmbedResponsive>
      </div>
    );
  },
  twitter: (params, children) => {
    const { url, card, conversation } = params;

    return (
      <div className={cs(styles.block, styles.twitter)}>
        <TwitterEmbed url={url} card={card} conversation={conversation}/>
      </div>
    );
  },
  instagram: (params, children) => {
    const { url, caption, embeddingResult } = params;

    if (embeddingResult) {
      return (
        <div className={cs(styles.block, styles.instagram)}>
          <InstagramEmbed data={embeddingResult} caption={caption}/>
        </div>
      );
    }

    if (url) {
      return (
        <p>
          <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
        </p>
      );
    }

    return null;
  },
  news: (params, children) => (
    <div className={styles.block}>
      <MaterialsList items={params.newsList} header={params.header}/>
    </div>
  ),
  match: (params, children) => (
    <div className={styles.block}>
      <MatchWidget matchUrn={params.matchUrn}/>
    </div>
  ),
};
