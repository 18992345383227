import { memo, useEffect } from "react";
import "./InstagramEmbed.module.css";

export const InstagramEmbed = memo(props => {
  const { data, caption } = props;
  const { html } = data;

  useEffect(() => {
    if (window.instgrm && window.instgrm.Embeds) {
      window.instgrm.Embeds.process();
    }
  }, []);

  if (!html) {
    return null;
  }

  const resultHtml = caption ? html : html.replace(" data-instgrm-captioned ", " ");

  return (
    <div dangerouslySetInnerHTML={{ __html: resultHtml }}/>
  );
});
