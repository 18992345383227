import { useCallback, useEffect, useRef, useState } from "react";
import cs from "classnames";
import { format, isToday, isYesterday } from "date-fns";
import { ru } from "date-fns/locale";
import axios from "axios";
import Centrifuge from "centrifuge";
import env from "env";
import { ymGoal } from "ym";
import { useConfig } from "components/App/ConfigContext";
import { Block } from "components/Block";
import { Content } from "components/Content";
import styles from "./BlockLive.module.css";

function useApiData() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [material, setMaterial] = useState(null);

  const config = useConfig();

  async function reload() {
    setLoading(true);
    setError(false);

    try {
      const { data: { items } } = await axios.get(`${env("API_URL")}/news/v1/materials/feed`, {
        params: {
          tag: env("API_URL") === "https://api.sport24.gg" ? config["live-tag-id-gg"] : config["live-tag-id-prod"],
          type: "LIVE",
          limit: 1,
        },
      });

      if (!items[0]) {
        setError(true);
        return;
      }

      const { urn } = items[0];

      const { data } = await axios.get(`${env("API_URL")}/news/v1/materials/${encodeURIComponent(urn)}`);

      setMaterial(data);
    } catch (e) {
      setError(true);
    }

    setLoading(false);
  }

  useEffect(() => reload(), []); // eslint-disable-line

  return { material, loading, error };
}

export function BlockLive(props) {
  const { onUpdate} = props;
  const { material, loading } = useApiData();

  const config = useConfig();

  useEffect(() => {
    if (material && onUpdate) {
      onUpdate();
    }
  }, [onUpdate, material]);

  return (
    <Block title={config["live-title"]} line className={cs(styles.root, props.className)} loading={loading} scroll visible={props.visible} onClick={() => ymGoal("click_5")}>
      {material && <LiveEvents materialId={material.id} disableLive={material.disableLive} events={material?.liveEvents}/>}
      {
        material &&
        <div className={styles.footer}>
          {/* eslint-disable react/jsx-no-target-blank */}
          <a href={`${env("SITE_BASE_URL")}/live/-/${encodeURIComponent(material.urn)}`} target="_blank" className={styles.button}>Вся трансляция ➜</a>
        </div>
      }
    </Block>
  );
}

function LiveEvents(props) {
  const { materialId, disableLive } = props;
  const [ events, setEvents ] = useState(props.events);

  const currentEventsRef = useRef(events);

  const processMessage = useCallback(data => {
    const events = currentEventsRef.current;

    let buffer;

    if (data.published) {
      buffer = [...events];
      if (!buffer.filter(event => event.id === data.id).length) {
        buffer.push(data);
      } else {
        buffer = buffer.map(el => (el.id === data.id ? data : el));
      }
    } else {
      buffer = [...events.reduce((a, b) => (b.id === data.id ? a : a.concat(b)), [])];
    }

    buffer.sort((a, b) => (a.publishDate < b.publishDate ? 1 : -1));

    currentEventsRef.current = buffer;
    setEvents(buffer);
  }, []);

  useEffect(() => {
    if (!disableLive && env("LIVE_API_URL")) {
      const centrifuge = new Centrifuge(env("LIVE_API_URL"));
      centrifuge.subscribe(`livenews:${materialId}`, message => processMessage(message.data));
      centrifuge.connect();

      return () => {
        centrifuge.disconnect();
      };
    }
  }, [materialId, disableLive, processMessage]);

  return events.map(event =>
    <Event key={event.id} event={event}/>
  );
}

function Event(props) {
  const { publishDate, content } = props.event;

  return (
    <div className={styles.event}>
      <div className={styles.time}>{getTime(publishDate)}</div>
      <div className={styles.content}>
        <Content content={content}/>
      </div>
    </div>
  )
}

function getTime(time) {
  const date = new Date(time);

  if (isToday(date)) {
    return format(date, "H:mm");
  }

  if (isYesterday(date)) {
    return `Вчера, ${format(date, "H:mm")}`;
  }

  return format(date, "d MMMM y, H:mm", { locale: ru });
}
