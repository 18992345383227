import env from "env";
import { createImageUrlById } from "images";
import styles from "./MaterialsList.module.css";

function Material({ material }) {
  const { title, urn, image } = material;

  const url = `${env("SITE_BASE_URL")}/news/-/${encodeURIComponent(urn)}`;

  const imageUrl = image ? createImageUrlById(image, 300, 300) : null;

  return (
    <a href={url} className={styles.material} target="_blank">{/* eslint-disable-line react/jsx-no-target-blank */}
      {
        imageUrl &&
        <img src={imageUrl} alt={title} className={styles.image}/>
      }
      <div className={styles.text}>
        <span>{title}</span>
      </div>
    </a>
  );
}

export function MaterialsList(props) {
  const { header, items } = props;

  return (
    <div className={styles.root}>
      {
        header &&
        <div className={styles.title}>{header}</div>
      }
      <div>
        {
          items.map(material =>
            <Material key={material.urn} material={material}/>,
          )
        }
      </div>
    </div>
  );
}
