import cs from "classnames";
import "flag-icon-css/css/flag-icon.css";

const replaces = {
  en: "gb-eng",
  kx: "xk",
};

export function Flag(props) {
  let { code, style = {} } = props;

  if (!code) {
    return null;
  }

  code = code.toLowerCase();

  if (replaces[code]) {
    code = replaces[code];
  }

  return (
    <span className={cs("flag-icon", `flag-icon-${code}`)} style={{ borderRadius: 2, ...style }}/>
  );
}
