import { ymGoal } from "ym";
import { useConfig } from "components/App/ConfigContext";
import { CurrentDateTime } from "components/CurrentDateTime";
import { CurrentWeather } from "components/CurrentWeather";
import { Share } from "components/Share";
import { Logo } from "components/Logo";
import zenTextUrl from "./text.svg";
import zenLogoUrl from "./logo.svg";
import { ReactComponent as SvgBetcity } from "./betcity.svg";
import styles from "./Header.module.css";

export function Header() {
  const config = useConfig();

  return (
    <header className={styles.root}>
      <div className="content">
        <div className={styles.body}>
          <div className={styles.sport24}>
            <Logo/>
          </div>
          {
            config["header-betcity"] &&
            <div className={styles.betcity}>
              <div>При поддержке</div>
              {/* eslint-disable react/jsx-no-target-blank */}
              <a href={config["header-betcity-url"]} target="_blank" rel="noopener" onClick={() => ymGoal("click_2")}>
                <SvgBetcity/>
              </a>
            </div>
          }
          <div className={styles.title}>
            <img src={zenTextUrl} className={styles.zenText} alt="Олимпийский Дзен"/>
            <img src={zenLogoUrl} className={styles.zenLogo} alt="Лого"/>
          </div>
          <div className={styles.info}>
            <CurrentDateTime/>
            <div className={styles.weather}>
              <CurrentWeather city="Moscow" title="Москва"/>
              <CurrentWeather city="Beijing" title="Пекин"/>
            </div>
          </div>
          <div className={styles.share}>
            <Share/>
          </div>
        </div>
      </div>
    </header>
  );
}
