import { useEffect, useRef, useState } from "react";

export function BannerAdfox(props) {
  const { ownerId, params, onShowChange, onRender, style } = props;

  const [id, setId] = useState();

  const containerRef = useRef();

  useEffect(() => {
    setId(`adfox_${(Math.random() * 10000000).toFixed(0)}`);
  }, [setId]);

  useEffect(() => {
    if (id) {
      window.yaContextCb.push(() => {
        window.Ya.adfoxCode.create({
          ownerId: ownerId,
          containerId: id,
          params: params,
          onRender: onRender ?? (() => {}),
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownerId, id, JSON.stringify(params)]);

  useEffect(() => {
    if (containerRef.current && onShowChange) {
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.target.children.length) {
            observer.disconnect();
            onShowChange(containerRef.current);
          }
        });
      });

      observer.observe(containerRef.current, {
        childList: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [onShowChange]);

  if (id) {
    return (
      <div ref={containerRef} id={id} style={style}/>
    );
  }

  return null;
}
