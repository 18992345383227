import env from "env";
import { ymGoal } from "ym";
import { ReactComponent as SvgSport24 } from "./sport24ru.svg";
import styles from "./Logo.module.css";

export function Logo() {
  return (
    <div className={styles.root}>
      <div className={styles.sport24}>
        {/* eslint-disable react/jsx-no-target-blank */}
        <a href={env("SITE_BASE_URL")} target="_blank" rel="noopener" onClick={() => ymGoal("click_1")}>
          <SvgSport24/>
        </a>
      </div>
    </div>
  );
}
