import { Fragment, isValidElement } from "react";
import { elements } from "./elements";

export function Content(props) {
  const { content } = props;

  if (!content) {
    return null;
  }

  let original = 0;

  return content.map((data, i) => {
    const key = isValidElement(data[0]) ? data[0].key ?? `custom-${i}` : `original-${original++}`;

    return (
      <Fragment key={key}>
        <Element data={data} elements={elements}/>
      </Fragment>
    );
  });
}

function Element(props) {
  const { data, elements } = props;

  if (data.length === 1) {
    return data[0];
  }

  const [name, children, params] = data;

  if (elements[name]) {
    return elements[name](params, children);
  }

  console.warn("Unsupported element %o", name);

  return null;
}
