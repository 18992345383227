import { useEffect, useState } from "react";
import cs from "classnames";
import { format, isToday, isYesterday } from "date-fns";
import { ru } from "date-fns/locale";
import axios from "axios";
import env from "env";
import { ymGoal } from "ym";
import { createImageUrlById } from "images";
import { useConfig } from "components/App/ConfigContext";
import { Block } from "components/Block";
import styles from "./BlockNews.module.css";

const UPDATE_INTERVAL = 60 * 1000;

function useApiData() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [items, setItems] = useState(null);

  const config = useConfig();

  async function reload() {
    setLoading(true);
    setError(false);

    try {
      const { data: { items } } = await axios.get(`${env("API_URL")}/news/v1/materials/feed`, {
        params: {
          tag: env("API_URL") === "https://api.sport24.gg" ? config["news-tag-id-gg"] : config["news-tag-id-prod"],
          type: config["news-types"],
          limit: config["news-limit"],
        },
      });

      setItems(items);
    } catch (e) {
      setError(true);
    }

    setLoading(false);
  }

  useEffect(() => {
    reload();

    if (UPDATE_INTERVAL) {
      const interval = setInterval(reload, UPDATE_INTERVAL);

      return () => {
        clearInterval(interval);
      }
    }
  }, []); // eslint-disable-line

  return { items, loading, error };
}

export function BlockNews(props) {
  const { items, loading } = useApiData();

  const config = useConfig();

  useEffect(() => {
    if (items) {
      props.onUpdate?.();
    }
  }, [props, items]);

  return (
    <Block title={config["news-title"]} line className={cs(styles.root, props.className)} loading={loading} scroll visible={props.visible}>
      {
        items?.map(data =>
          <NewsItem key={data.id} data={data}/>,
        )
      }
    </Block>
  );
}

function NewsItem(props) {
  const { urn, previewImage, hidePreviewImage, title, publishDate } = props.data;

  const url = `${env("SITE_BASE_URL")}/news/-/${encodeURIComponent(urn)}`;

  const imageUrl = (previewImage && !hidePreviewImage) ? createImageUrlById(previewImage.image, 400, 400) : null;

  return (
    <a href={url} target="_blank" className={styles.newsItem} onClick={() => ymGoal("click_3")}>{/* eslint-disable-line react/jsx-no-target-blank */}
      {
        imageUrl &&
        <div className={styles.image}>
          <img src={imageUrl} alt={title}/>
        </div>
      }
      <div className={styles.title}>{title}</div>
      <div className={styles.publishDate}>{getTime(publishDate)}</div>
    </a>
  );
}

function getTime(time) {
  const date = new Date(time);

  if (isToday(date)) {
    return `Сегодня, ${format(date, "H:mm")}`;
  }

  if (isYesterday(date)) {
    return `Вчера, ${format(date, "H:mm")}`;
  }

  return format(date, "d MMMM y, H:mm", { locale: ru });
}
