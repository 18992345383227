import { createContext, useCallback, useContext, useEffect, useState } from "react";

const DESKTOP_MIN_WIDTH = 1024;

const IsMobileContext = createContext(null);

export function useIsMobile() {
  return useContext(IsMobileContext).isMobile;
}

export function IsMobileContextProvider({ children }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < DESKTOP_MIN_WIDTH);

  const handleWindowSizeChange = useCallback(() => {
    const currentIsMobile = window.innerWidth < DESKTOP_MIN_WIDTH;
    if (currentIsMobile !== isMobile) {
      setIsMobile(currentIsMobile);
    }
  }, [isMobile]);

  useEffect(() => {
    const options = {
      passive: true,
    }

    window.addEventListener("resize", handleWindowSizeChange, options);
    window.addEventListener("deviceorientation", handleWindowSizeChange, options);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
      window.removeEventListener("deviceorientation", handleWindowSizeChange);
    };
  }, [handleWindowSizeChange])

  return (
    <IsMobileContext.Provider value={{ isMobile }}>
      {children}
    </IsMobileContext.Provider>
  );
}

export function IsMobile(props) {
  const isMobile = useIsMobile();
  return isMobile ? props.children : null;
}

export function IsDesktop(props) {
  const isMobile = useIsMobile();
  return isMobile ? null : props.children;
}
