import { useConfig } from "components/App/ConfigContext";
import { ReactComponent as SvgYoutube } from "./youtube.svg";
import { ReactComponent as SvgVk } from "./vk.svg";
import { ReactComponent as SvgOk } from "./ok.svg";
import { ReactComponent as SvgTwitter } from "./twitter.svg";
import { ReactComponent as SvgTelegram } from "./telegram.svg";
import { ReactComponent as SvgInstagram } from "./instagram.svg";
import styles from "./Socials.module.css";

export function Socials() {
  const config = useConfig();

  return (
    <div className={styles.root}>
      {config["footer-socials-youtube"] && <a href={config["footer-socials-youtube"]} target="_blank" rel="noopener noreferrer"><SvgYoutube/></a>}
      {config["footer-socials-vk"] && <a href={config["footer-socials-vk"]} target="_blank" rel="noopener noreferrer"><SvgVk/></a>}
      {config["footer-socials-ok"] && <a href={config["footer-socials-ok"]} target="_blank" rel="noopener noreferrer"><SvgOk/></a>}
      {config["footer-socials-twitter"] && <a href={config["footer-socials-twitter"]} target="_blank" rel="noopener noreferrer"><SvgTwitter/></a>}
      {config["footer-socials-telegram"] && <a href={config["footer-socials-telegram"]} target="_blank" rel="noopener noreferrer"><SvgTelegram/></a>}
      {config["footer-socials-instagram"] && <a href={config["footer-socials-instagram"]} target="_blank" rel="noopener noreferrer"><SvgInstagram/></a>}
    </div>
  );
}
